'use client';

import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { MouseParallax } from 'react-just-parallax';
import { useAtomValue } from 'jotai';

import Button, { ButtonVariants } from '../Button';
import Ellipse, { EllipseVariants } from '../Ellipse';

import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';
import { theme } from '../../store/theme';

import styles from './Error.module.scss';
import Logo from '../Logo';

const Page404 = () => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const appTheme = useAtomValue(theme);
  return (
    <>
      <div
        aria-hidden='true'
        className={styles.background}
      >
        <MouseParallax
          isAbsolutelyPositioned
          strength={0.05}
        >
          <Ellipse
            variant={EllipseVariants.DRAW}
            size={deviceSize === ScreenSize.MOBILE ? 572 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 623 : 750}
            className={classNames(styles.parallax, styles.parallax__draw)}
          />
        </MouseParallax>
        <MouseParallax
          isAbsolutelyPositioned
          strength={0.12}
        >
          <Ellipse
            variant={EllipseVariants.NOISE}
            size={89}
            className={classNames(styles.parallax, styles.parallax__noise)}
          />
        </MouseParallax>
        <MouseParallax
          isAbsolutelyPositioned
          strength={0.08}
        >
          <Ellipse
            variant={EllipseVariants.BORDER}
            size={deviceSize < ScreenSize.TABLET_PORTRAIT ? 23 : 25}
            className={classNames(styles.parallax, styles.parallax__border)}
          />
        </MouseParallax>
        <MouseParallax
          isAbsolutelyPositioned
          strength={0.1}
        >
          <Ellipse
            variant={EllipseVariants.GRADIENT}
            size={38}
            className={classNames(styles.parallax, styles.parallax__gradient)}
          />
        </MouseParallax>
        <MouseParallax
          isAbsolutelyPositioned
          strength={0.1}
        >
          <span className={classNames(styles.parallax, styles.parallax__four, styles.parallax__four_top)}>
            <Image
              fill
              src={`/media/4-${appTheme}.svg`}
              alt=''
            />
          </span>
        </MouseParallax>
        <MouseParallax
          isAbsolutelyPositioned
          strength={0.1}
        >
          <span className={classNames(styles.parallax, styles.parallax__four, styles.parallax__four_bottom)}>
            <Image
              fill
              src={`/media/4-${appTheme}.svg`}
              alt=''
            />
          </span>
        </MouseParallax>
        <span className={classNames(styles.ellipse, styles.ellipse__top)} />
        <span className={classNames(styles.ellipse, styles.ellipse__bottom)} />
      </div>
      <section className={styles.wrapper}>
        {/* Using a normal anchor tag until Link works on the not-found page */}
        <a
          href='/'
          aria-label='Get back to main page'
          className={styles.logo}
        >
          <Logo
            width={156}
            height={52}
          />
        </a>
        <div className={styles.content}>
          <h2 className={styles.title}>Houston, we have a problem!</h2>
          <p className={styles.text}>Take a moment to pause, recalibrate your coordinates, and set forth on a journey of discovery.</p>
          <a href='/'>
            <Button
              className={styles.btn}
              variant={ButtonVariants.OUTLINED}
              icon={(
                <svg className={styles.btn__icon}>
                  <use
                    xlinkHref='/media/icon-nest.svg#iconNest'
                    href='/media/icon-nest.svg#iconNest'
                  />
                </svg>
              )}
              iconSize={{
                width: 24,
                height: 24,
              }}
            >
              Back to home
            </Button>
          </a>
        </div>
      </section>
    </>
  );
};

export default Page404;
