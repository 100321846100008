import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Image from 'next/image';

import { theme } from '../store/theme';

import styles from './Ellipse.module.scss';

export enum EllipseVariants {
  DRAW = 'draw',
  BORDER = 'border',
  NOISE = 'noise',
  GRADIENT = 'gradient',
  GRIBS = 'gribs',
  GRAPH = 'graph',
  CUBE = 'cube',
}

type EllipseProps = {
  className?: string;
  size: number;
  variant: EllipseVariants;
};

const Ellipse = ({ className, size, variant }: EllipseProps) => {
  const appTheme = useAtomValue(theme);

  const Images = {
    [EllipseVariants.DRAW]: `/media/ellipses/ellipse-draw-${appTheme}.svg`,
    [EllipseVariants.BORDER]: '/media/ellipses/ellipse-border.svg',
    [EllipseVariants.NOISE]: `/media/ellipses/elps_grain_${appTheme}.webp`,
    [EllipseVariants.GRADIENT]: `/media/ellipses/ellipse-gradient-${appTheme}.svg`,
    [EllipseVariants.GRIBS]: '/media/ellipses/ellipse-gribs.svg',
    [EllipseVariants.GRAPH]: '/media/ellipses/graph.svg',
    [EllipseVariants.CUBE]: '/media/ellipses/grain-cube.svg',
  };

  return (
    <Image
      src={Images[variant]}
      alt=''
      width={size}
      height={size}
      className={classnames(className, {
        [styles.ellipseBorder]: variant === EllipseVariants.BORDER,
      })}
    />
  );
};

export default Ellipse;
