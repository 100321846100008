'use client';

import { atomWithStore } from 'jotai-zustand';
import { createStore, StoreApi } from 'zustand/vanilla';

export enum ScreenSize {
  MOBILE,
  TABLET_PORTRAIT,
  TABLET_LANDSCAPE,
  DESKTOP_SMALL,
  DESKTOP,
  DESKTOP_BIG,
  DESKTOP_HUGE,
}

const screenSizes = [
  { size: ScreenSize.MOBILE, breakpoints: { max: 768 } },
  { size: ScreenSize.TABLET_PORTRAIT, breakpoints: { min: 768, max: 1024 } },
  { size: ScreenSize.TABLET_LANDSCAPE, breakpoints: { min: 1024, max: 1280 } },
  { size: ScreenSize.DESKTOP_SMALL, breakpoints: { min: 1280, max: 1440 } },
  { size: ScreenSize.DESKTOP, breakpoints: { min: 1440, max: 1920 } },
  { size: ScreenSize.DESKTOP_BIG, breakpoints: { min: 1920, max: 2560 } },
  { size: ScreenSize.DESKTOP_HUGE, breakpoints: { min: 2560 } },
];

type CheckScreenSizeProps = {
  size: ScreenSize,
  breakpoints: { min?: number, max?: number },
};

export const deviceScreenSizeStore: StoreApi<ScreenSize> = createStore(() => (ScreenSize.DESKTOP));

const deviceScreenSize = atomWithStore(deviceScreenSizeStore);

const checkScreenSize = ({ size, breakpoints }: CheckScreenSizeProps) => {
  if (typeof window !== 'undefined') {
    const minBreakpoint = breakpoints?.min ? (window.innerWidth as number) >= breakpoints.min : true;
    const maxBreakpoint = breakpoints?.max ? (window.innerWidth as number) < breakpoints.max : true;
    deviceScreenSizeStore.getState() !== size && minBreakpoint && maxBreakpoint && deviceScreenSizeStore.setState(size);
  }
};
screenSizes.forEach(checkScreenSize);

if (typeof window !== 'undefined') {
  window.addEventListener('resize', () => {
    screenSizes.forEach(checkScreenSize);
  });
}

export default deviceScreenSize;
