import classnames from 'classnames';
import Image from 'next/image';
import styles from './Logo.module.scss';

interface LogoProps {
  width: number,
  height: number,
  className?: string,
}

const Logo = ({ width, height, className }: LogoProps) => (
  <div
    className={classnames(styles.logo, className)}
    style={{ width, height }}
  >
    <Image
      src='/media/logo.svg'
      alt='logo'
      width={width}
      height={height}
    />
  </div>
);

export default Logo;
