import { atomWithStore } from 'jotai-zustand';
import { createStore, StoreApi } from 'zustand/vanilla';
import { setCookie } from '../cookiesControls'; // Make sure you have a setCookie function

export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

const initialTheme: Theme = Theme.DARK;

export const themeStore: StoreApi<Theme> = createStore(() => initialTheme);

if (typeof document !== 'undefined') {
  document.documentElement.dataset.theme = initialTheme;
  themeStore.subscribe(() => {
    const newTheme = themeStore.getState();
    document.documentElement.dataset.theme = newTheme;
    setCookie('theme', newTheme, 365);
  });
}

export const theme = atomWithStore(themeStore);
